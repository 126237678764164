<template>
  <section class="others-section">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex links-btn">
          <router-link to="/get-involved?go=student">
            <img src="../assets/img/visitor/reading.png" width="50" height="50" alt="">
            <span>Student</span>
          </router-link>
          <router-link to="/get-involved?go=educator">
            <img src="../assets/img/visitor/education.png" width="50" height="50" alt="">
            <span>Educator</span>
          </router-link>
          <router-link to="/get-involved?go=alumni">
            <img src="../assets/img/visitor/student.png" width="50" height="50" alt="">
            <span>Alumni</span>
          </router-link>
          <router-link to="/get-involved?go=supporter">
            <img src="../assets/img/visitor/hand.png" width="50" height="50" alt="">
            <span>Supporter</span>
          </router-link>
          <router-link to="/get-involved?go=partner">
            <img src="../assets/img/visitor/cooperation.png" width="50" height="50" alt="">
            <span>Partner</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "OthersArea",
  }
</script>

<style scoped lang="scss">
  .others-section {
    background: linear-gradient(90deg, #31bfd7, #31bfd7);

    .others-one-content {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &::before, &::after {
      display: none;
    }

    .links-btn {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 20px;
        background-size: 100%;
        background-position: center;
        position: relative;
        width: 20%;
        padding: 20px 0;
        transition: 0.5s;
        border-radius: 0;

        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        &:hover {
          transform: scale(1.1);
          background: #7b6ff0;
          box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
        }

        span, img {
          position: relative;
          z-index: 2;
        }

        img {
          width: 50px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .others-section .links-btn {
      flex-wrap: wrap;
      justify-content: center;
      a {
        width: 33.33%;
      }
    }
  }

  @media (max-width: 450px) {
    .others-section .links-btn {
      a {
        width: 50%;
      }
    }
  }
</style>
